import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTh, faThList } from '@fortawesome/free-solid-svg-icons';

const FarmFilter = ({ isListView, setListView, stakedOnly, setStakedOnly, activeOnly, setActiveOnly, search, setSearch, sort, setSort, saveFilters }) => {
  const onToggleStakedOnly = () => {
    setStakedOnly(prevState => !prevState);
    saveFilters({ stakedOnly: !stakedOnly });
  }

  const onSearchChange = (evt) => {
    const { target } = evt;
    setSearch(target.value);
    saveFilters({ search: target.value });
  }

  const onSortChange = (evt) => {
    const { target } = evt;
    setSort(target.value);
    saveFilters({ sort: target.value });
  }

  return (
    <div className="columns is-mobile is-multiline is-justify-content-center-desktop is-align-items-center">
      <div className="column is-half-mobile is-narrow-desktop">
        <div className="buttons has-addons">
          <button
            onClick={() => {
              if (!isListView) {
                setListView(true);
                saveFilters({ isListView: true });
              }
            }}
            className={`button is-rounded ${isListView ? 'is-link is-selected' : ''}`}
          >
            <span className="icon">
              <FontAwesomeIcon icon={ faThList } />
            </span>
          </button>
          <button
            onClick={() => {
              if (isListView) {
                setListView(false);
                saveFilters({ isListView: false });
              }
            }}
            className={`button is-rounded ${isListView ? '' : 'is-link is-selected'}`}
          >
            <span className="icon">
              <FontAwesomeIcon icon={ faTh } />
            </span>
          </button>
        </div>
      </div>
      <div className="column is-half-mobile is-narrow-desktop">
        <div className="field">
          <input id="stakedOnly" type="checkbox" name="stakedOnly" className="switch is-rounded is-outlined" checked={ stakedOnly } onChange={ onToggleStakedOnly } />
          <label htmlFor="stakedOnly">Staked Only</label>
        </div>
      </div>
      <div className="column is-full-mobile">
        <div className="buttons has-addons">
          <button
            onClick={() => {
              if (!activeOnly) {
                setActiveOnly(true);
                saveFilters({ activeOnly: true });
              }
            }}
            className={`button is-rounded ${activeOnly ? 'is-link is-selected' : ''}`}
          >
            Active
          </button>
          <button
            onClick={() => {
              if (activeOnly) {
                setActiveOnly(false);
                saveFilters({ activeOnly: false });
              }
            }}
            className={`button is-rounded ${activeOnly ? '' : 'is-link is-selected'}`}
          >
            Finished
          </button>
        </div>
      </div>
      <div className="column is-half-mobile is-narrow-desktop">
        <div className="select is-rounded">
          <select value={ sort } onChange={ onSortChange }>
            <option value="hot">Sort By HOT</option>
            <option value="apr">Sort By APR</option>
            <option value="multiplier">Sort by Multiplier</option>
            <option value="earned">Sort by Earned</option>
          </select>
        </div>
      </div>
      <div className="column is-half-mobile is-narrow-desktop">
        <div className="field">
          <p className="control has-icons-left">
            <input className="input is-rounded" type="text" placeholder="Search..." value={ search } onChange={ onSearchChange } />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={ faSearch } />
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default FarmFilter;
