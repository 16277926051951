import BigNumber from 'bignumber.js';
import multicall from '../blockchain/multicall';
import nativeTokenAbi from '../config/abis/nativeToken.json';
import { getAddress } from '../utils/commons';
import { getContract } from './commons';

export const fetchAntiBot = async () => {
  const nativeTokenAddress = getAddress(process.env.REACT_APP_NATIVE_TOKEN);

  const nativeTokenCalls = [
    {
      address: nativeTokenAddress,
      name: 'antiBotWorking',
    },
    {
      address: nativeTokenAddress,
      name: 'maxUserHoldAmount',
    },
    {
      address: nativeTokenAddress,
      name: 'maxUserTransferAmount',
    },
    {
      address: nativeTokenAddress,
      name: 'blacklistLength',
    },
    {
      address: nativeTokenAddress,
      name: 'decimals',
    },
  ];

  const nativeTokenResults = await multicall(nativeTokenAbi, nativeTokenCalls);

  return {
    antiBotWorking: nativeTokenResults[0][0],
    maxUserHoldAmount: new BigNumber(nativeTokenResults[1]).toJSON(),
    maxUserTransferAmount: new BigNumber(nativeTokenResults[2]).toJSON(),
    blacklistLength: new BigNumber(nativeTokenResults[3]).toJSON(),
    decimals: nativeTokenResults[4][0],
    firstLoad: false,
  }
};

export const blackListCheck = async (address) => {
  const nativeTokenContract = getContract(process.env.REACT_APP_NATIVE_TOKEN, nativeTokenAbi);
  return await nativeTokenContract.blacklistCheck(address);
}
