import React, { useState } from 'react';
import Countdown from 'react-countdown';
import BigNumber from 'bignumber.js';
import { faExternalLinkAlt, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getAddress, getLpAddress, swapAddress } from '../../../../../utils/commons';

const FarmCardFooterSection = ({ farmData, nativeTokenPriceDefault }) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const toggleDetails = (evt) => {
    evt.preventDefault();
    setIsDetailsOpen(!isDetailsOpen);
  }

  const linkToViewOn = () => {
    let token;
    if (farmData.isTokenOnly) {
      token = getAddress(farmData.token);
    } else {
      token = getLpAddress(farmData.token, farmData.quoteToken);
    }

    return `${process.env.REACT_APP_EXPLORER}/token/${token}`;
  }

  return (
    <>
      { nativeTokenPriceDefault ? (
        <div className="has-text-centered">Staking starts at <Countdown date={ Number(process.env.REACT_APP_LAUNCH_AT) * 1000 } /></div>
      ) : (
        <button type="button" className="button is-ghost is-fullwidth" onClick={ toggleDetails }>
          <span className="icon-text">
            { isDetailsOpen ? 'Hide' : 'Details' }
            <span className="icon ml-1">
              <FontAwesomeIcon icon={ isDetailsOpen ? faAngleUp : faAngleDown } />
            </span>
          </span>
        </button>
      )}
      <div className={ `py-3 ${isDetailsOpen ? null : 'is-hidden'}` }>
        <div className="level is-mobile mb-1">
          <div className="level-left">
            <div className="level-item">
              Deposit
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <a className="button is-text" href={ swapAddress(farmData) } target="_blank" rel="noreferrer">
                <span className="icon-text">
                  { farmData.name }
                  <span className="icon ml-1">
                    <FontAwesomeIcon icon={ faExternalLinkAlt } />
                  </span>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="level is-mobile mb-1">
          <div className="level-left">
            <div className="level-item">
              Total Liquidity
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <h6 className="is-size-6 has-text-weight-semibold">${ new BigNumber(farmData.totalValue).toFormat(2) }</h6>
            </div>
          </div>
        </div>
        <div className="is-flex is-justify-content-center">
          <a href={ linkToViewOn() } className="button is-ghost" target="_blank" rel="noreferrer">
            <span className="icon-test">
              View on Explorer
              <span className="icon ml-1">
                <FontAwesomeIcon icon={ faExternalLinkAlt } />
              </span>
            </span>
          </a>
        </div>
      </div>
    </>
  );
}

export default FarmCardFooterSection;
